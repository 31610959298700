<template>
<div class="card-box-full">
  <div class="card-box-header">
    Whois
  </div>

    <div class="form-group row" style="margin-top: 15px; padding: 5px">
      <div class="col-md-12">
        <div class="input-group">

          <input class="form-control" v-model="domain" type="url" autocomplete="on" placeholder="Enter a domain" :disabled="isExecuting" @keyup.enter="execute()">
          <div class="input-group-append" color="primary">
            <CButton @click="execute()" color="primary" :disabled="isExecuting || domain.length == 0" style="width: 80px">
              Lookup
            </CButton>
          </div>
        </div>

        <!-- ----------------------------------------------------------------- -->
        <div v-if="isExecuting" class="spinner-border spinner-border-sm text-primary" role="status" style="margin-top: 15px">
          <span class="sr-only"></span>
        </div>

        <!-- ----------------------------------------------------------------- -->
        <div v-if="errorMessage">
          <div class="alert alert-danger" style="margin: 15px 0px 0px 0px" role="alert">{{ errorMessage }}</div>
        </div>

        <!-- ----------------------------------------------------------------- -->
        <div v-if="whoisData" style="margin-top: 15px">
          <hr>
          <table cellpadding="10">
            <tr>
              <td valign="middle">
                <h6>Domain</h6>
              </td>
              <td>
                <div style="font-family: monospace; margin-top: -10px">{{ whoisData.domain }}</div>
              </td>
            </tr>
            <tr>
              <td>
                <h6>TLD</h6>
              </td>
              <td>
                <div style="font-family: monospace; margin-top: -10px">{{ whoisData.tld }}</div>
              </td>
            </tr>
            <tr>
              <td>
                <h6>Created</h6>
              </td>
              <td>
                <div style="font-family: monospace; margin-top: -10px">{{ whoisData.created }}  (<i><timeago :datetime="whoisData.created"></timeago></i>) </div>
              </td>
            </tr>
            <tr>
              <td>
                <h6>Expires</h6>
              </td>
              <td>
                <div style="font-family: monospace; margin-top: -10px">{{ whoisData.expires }}  (<i><timeago :datetime="whoisData.expires"></timeago></i>) </div>
              </td>
            </tr>
            <tr>
              <td>
                <h6>Registrar</h6>
              </td>
              <td>
                <div style="font-family: monospace; margin-top: -10px">{{ whoisData.registrar }}</div>
              </td>
            </tr>
            <tr>
              <td>
                <h6>Registrant</h6>
              </td>
              <td>
                <div style="font-family: monospace; margin-top: -10px">{{ whoisData.registrant }}</div>
              </td>
            </tr>
            <tr>
              <td colspan="2">
                <CButton @click="clickedDomainLookup()" color="info" style="width: 100%">
                  Perform a domain lookup
                </CButton>
              </td>
            </tr>
          </table>
        </div>

      </div>
    </div>

</div>
</template>

<script>
export default {
  data() {
    return {
      domain: "",
      errorMessage: null,
      isExecuting: false,
      whoisData: null
    }
  },
  methods: {
    //--------------------------------------------------------------------------
    clickedDomainLookup() {
      this.$router.push({
        path: "/domain-reputation/domain-lookup/" + encodeURIComponent(this.domain)
      });
    },
    //--------------------------------------------------------------------------
    execute() {
      if (this.domain.length == 0) {
        return;
      }

      if (this.isExecuting) {
        return;
      } else {
        this.whoisData = null;
        this.errorMessage = null;
        this.isExecuting = true;
      }

      // Perform the lookup
      var that = this;
      var method = "GET";
      var url = "https://domain-reputation.platform.robocalls.ai/whois?domain=" + encodeURIComponent(this.domain);
      var requiresSession = true;
      RobocallsAI.shared().execute(method, url, null, requiresSession, {
        onSuccess: function(code, body) {
          // Code = HTTP status code
          // Body = Response from server
          that.isExecuting = false;
          if (code != 200) {
            // Something went wrong
            var message = that.parseMessage(body);
            if (message) {
              that.errorMessage = message;
            } else {
              that.errorMessage = "Received a code " + code + " from the service";
            }
          } else {
            // Parse the accounts
            try {
              var result = JSON.parse(body);
              that.processResponse(result);
            } catch (err) {
              that.errorMessage = "Failed to parse response from the server";
            }
          }
        },
        onCancelled: function() {
          // Fired if the request was cancelled
          that.isExecuting = false;
        },
        onFailure: function(error) {
          // Fired if the request fails
          that.isExecuting = false;
          that.errorMessage = error;
        }
      })
    },
    //--------------------------------------------------------------------------
    parseMessage(body) {
      try {
        var result = JSON.parse(body);
        return result.message;
      } catch (err) {
        return "";
      }
    },
    //--------------------------------------------------------------------------
    processResponse(response) {
      if (!response || !response.whois) {
        this.errorMessage = "No whois data was returned";
        return;
      }

      this.whoisData = response.whois;
    },
  },
  mounted() {
    if (this.$route.params.domain) {
      this.domain = this.$route.params.domain;
      this.execute();
    }
  }
}
</script>
